@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?ngd4jr");
  src: url("fonts/icomoon.eot?ngd4jr#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?ngd4jr") format("truetype"),
    url("fonts/icomoon.woff?ngd4jr") format("woff"),
    url("fonts/icomoon.svg?ngd4jr#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-gobal:before {
  content: "\e901";
}
.icon-subscriber:before {
  content: "\e902";
}
.icon-terms:before {
  content: "\e905";
}
.icon-banner:before {
  content: "\e900";
}
.icon-blog:before {
  content: "\e801";
}
.icon-coupon:before {
  content: "\e802";
}
.icon-dashboard:before {
  content: "\e903";
}
.icon-dot:before {
  content: "\e904";
  font-size: 20px;
  vertical-align: middle;
}
.icon-faq:before {
  content: "\e805";
}
.icon-import:before {
  content: "\e906";
}
.icon-order:before {
  content: "\e907";
}
.icon-pap-request:before {
  content: "\e808";
}
.icon-price-match:before {
  content: "\e909";
}
.icon-product:before {
  content: "\e90a";
}
.icon-speciality:before {
  content: "\e90b";
}
.icon-staffs-roles:before {
  content: "\e90c";
}
.icon-supplier:before {
  content: "\e90d";
}
.icon-testimonials:before {
  content: "\e90e";
}
.icon-user-data:before {
  content: "\e90f";
}

.search-field{
  border: 1px solid #C7C7C7;
  font-size: 14px;
  padding: 10px 50px 10px 20px;
  width: 50%;
  outline: none;
}
.search-icon{
  position: absolute;
  top: 0;
  right: 52%;
  margin-top: 8px;
  img{
    width: 20px;
    height: 20px;
  }
}
