@import "../../assets/css/abstracts";

.notify {
  width: 30px;
  height: 30px;
  margin-top: 10px;
}
.logo {
  width: 190px;
  height: 70px;
  margin-left: 20px;
}
.menu {
  margin-left: 40px;
  cursor: pointer;
}
.notify-badge {
  background-color: #fc476e;
  border: 2px solid #fc476e;
  border-radius: 100%;
  color: white;
  width: fit-content;
  font-size: 10px;
  padding: 2px 2px;
  position: absolute;
  top: 0;
  margin-top: 25px;
  margin-left: 15px;
}
.avatar {
  margin-left: 40px;
  cursor: default;
}
.user-profile {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
  img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 40px;
  }
}
#user-name {
  color: #333333;
  font-size: 22px;
  font-family: "Mulish", sans-serif;
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 30px;
  cursor: pointer;
}
.align-icon {
  font-size: 24px;
  margin-left: 25px;
  margin-right: 30px;
}
.active-icon {
  background-color: #214c7f;
  border-left: 5px solid #00d9c5;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
  display: block;
  i {
    margin: 0px 15px;
    background-color: rgba(0, 217, 197, 0.25);
    border-radius: 25px;
    color: #00d9c5;
    padding: 10px;
  }
  span {
    color: #00d9c5;
  }
}
.inactive-icon {
  background-color: #29296d;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  padding-top: 20px;
  padding-bottom: 20px;
  display: block;
  color: white;
}
.inactive-icon:hover {
  color: white;
}
.header {
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  padding: 10px 0px;
  width: 100%;
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: space-between;
}
.show-drawer {
  width: 325px;
  height: 100%;
  background-color: #29296d;
  position: fixed;
  left: 0;
  top: 0;
  transition: 0.5s;
  overflow: auto;
  z-index: 12;
  padding-top: 20px;
  padding-bottom: 20px;
  ul {
    padding-left: 0px;
  }
}
.hide-drawer {
  width: 325px;
  height: 100%;
  background-color: #29296d;
  position: fixed;
  left: -350px;
  transition: 0.5s;
  top: 0;
  z-index: 12;
  overflow-y: scroll;
  padding-top: 20px;
  padding-bottom: 20px;
  ul {
    padding-left: 0px;
  }
}
.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
}
.overlay-none {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
}
.custom-scroll::-webkit-scrollbar {
  width: 12px;
}
.custom-scroll::-webkit-scrollbar-track {
  background: #214c7f;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background: #49aec0;
  border-radius: 10px;
}
.header-dropdown {
  #header-icon {
    margin-right: 10px;
    font-size: 20px;
  }
  span {
    color: #444444;
  }
}
