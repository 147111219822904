$root-font-size: 16px;

@function fontSize($size) {
  $remSize: $size / $root-font-size;
  @return #{$remSize}rem;
}

/* Font Size */
$fs: 38;
@while $fs > 8 {
  .fs-#{$fs} {
    font-size: $fs * 1px;
  }
  $fs: $fs - 1;
}

 
$line-height: 38;
@while $line-height > 8 {
  .line-height-#{$line-height} {
    line-height: $line-height * 1px;
  }
  $line-height: $line-height - 1;
}

/* Font-weight */
$fw: 800;
@while $fw > 400 {
  .fw-#{$fw} {
    font-weight: $fw;
  }
  $fw: $fw - 100;
}

/* Height */
$height: 700;
@while $height > 400 {
  .h-#{$height} {
    height: $height;
  }
  $height: $height - 50;
}
